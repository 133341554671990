<template>
    <div
      id="change-password"
    >
      <!-- Change Password Modal -->  
      <b-modal   
    v-model="showChangePasswordModal"   
    
    centered  
    no-close-on-esc  
    no-close-on-backdrop  
   
    hide-footer  
>  
<template #modal-header>  
        <h5 class="modal-title"> انتهت صلاحية كلمة المرور (مضى 30 يوماً ). الرجاء تغييرها </h5>  
          

    </template>  
    <validation-observer ref="simpleRules">  
        <b-form>  
            <b-form-group label-for="كلمة المرور السابقة" label="كلمة المرور">  
                <validation-provider #default="{ errors }" name="Password" vid="password" rules="required">  
                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : ''">  
                        <b-form-input  
                            id="register-password2"  
                            v-model="old_password"  
                            class="form-control-merge"  
                            :type="passwordFieldType"  
                            :state="errors.length > 0 ? false : null"  
                            name="register-password"  
                            placeholder="············"  
                        />  
                        <b-input-group-append is-text>  
                            <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />  
                        </b-input-group-append>  
                    </b-input-group>  
                    <small class="text-danger">{{ errors[0] }}</small>  
                </validation-provider>  
            </b-form-group>  

            <b-form-group label-for="كلمة المرور الجديدة" label="كلمة المرور الجديدة">  
                <validation-provider #default="{ errors }" name="confirmPassword" vid="confirmPassword" rules="required|password">  
                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : ''">  
                        <b-form-input  
                            id="register-password"  
                            v-model="new_password"  
                            class="form-control-merge"  
                            :type="passwordFieldType"  
                            :state="errors.length > 0 ? false : null"  
                            name="register-password"  
                            placeholder="············"  
                        />  
                        <b-input-group-append is-text>  
                            <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />  
                        </b-input-group-append>  
                    </b-input-group>  
                    <small class="text-danger">{{ errors[0] }}</small>  
                </validation-provider>  
            </b-form-group>  

            <b-row class="d-flex justify-content-center">  
    <b-col class="text-center">  
        <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click="UpdatePassword">  
            <span>حفظ</span>  
        </b-button>  
    </b-col>  
</b-row>   
        </b-form>  
    </validation-observer>  
    <template #modal-footer>  
        <!-- This template can be left empty because hide-footer is used -->  
    </template>  
</b-modal> 
    </div>
  </template>
  
  <script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import ProfileHeader from './ProfileHeader.vue'
  import ProfileRewards from './ProfileRewards'
  import { required, email } from '@validations'
  import ProfileInfo from './ProfileInfo.vue'
  import ProfileHolidays from './ProfileHolidays'
  import ProfileBank from './ProfileBank.vue'
  import profileBottom from './profileBottom.vue'
  import { togglePasswordVisibility } from '@core/mixins/ui/forms'
  import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BInputGroup,
  BCardTitle,
  BInputGroupAppend,
  BFormFile,
  BFormInvalidFeedback,
  BMediaAside,
  BImg,
  BMediaBody,
  BFormCheckbox,
} from 'bootstrap-vue'
  /* eslint-disable global-require */
  export default {
    components: {
      
    BFormFile,
    required,
    BInputGroup,
    togglePasswordVisibility,
    BInputGroupAppend,
    ValidationObserver,
    ValidationProvider,
    BButton,

    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BMedia,
    BFormInvalidFeedback,
    BMediaAside,
    BImg,
    BMediaBody,
    BFormCheckbox,
   
    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        profileData: { },
        old_password:"",
        new_password:'',
        showChangePasswordModal: false,
      }
    },
    computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
    created() {
this.openModal();
      this.$http.get('/api/v1/profile').then(res => {
        // console.log(res.data.data)
  
        this.profileData = res.data.data
  
      })
    },
    methods: {
      openModal(){
        this.showChangePasswordModal = true
      },
      UpdatePassword() {
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            const data = {
              new_password: this.new_password,
              old_password: this.old_password,
            }
            this.$http
              .put('/api/v1/reset_password', data)
             
              .then(res => {
                this.$router.push('/dashboard')
                this.$swal({
                  title: 'تم  تعديل كلمة المرور بنجاح',
                  icon: 'success',
                  confirmButtonText: 'موافق',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              }).catch(error => {
                this.$swal({
                  title: '',
                  text: `${error.response.data.message}`,
                  icon: 'error',
                  confirmButtonText: 'موافق',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
          }
        })
      })
    },
    }
  }
  /* eslint-disable global-require */
  </script>
  
  <style lang="scss" >
  @import '@core/scss/vue/pages/page-profile.scss';
  </style>
  